import { createContext, useEffect, useReducer, useState } from 'react';
import axios from 'axios';
import { MatxLoading } from 'app/components';
import { Apiurl } from 'app/apiconfig';

const initialState = {
  user: null,
  isInitialised: false,
  isAuthenticated: false
};

// const isValidToken = (accessToken) => {
//   if (!accessToken) return false;

//   const decodedToken = jwtDecode(accessToken);
//   const currentTime = Date.now() / 1000;
//   return decodedToken.exp > currentTime;
// };

// const setSession = (accessToken) => {
//   if (accessToken) {
//     localStorage.setItem('accessToken', accessToken);
//     axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
//   } else {
//     localStorage.removeItem('accessToken');
//     delete axios.defaults.headers.common.Authorization;
//   }
// };

const reducer = (state, action) => {
  switch (action.type) {
    case 'INIT': {
      const { isAuthenticated, user } = action.payload;
      return { ...state, isAuthenticated, isInitialised: true, user };
    }

    case 'LOGIN': {
      const { user } = action.payload;
      return { ...state, isAuthenticated: true, user };
    }

    case 'LOGOUT': {
      return { ...state, isAuthenticated: false, user: null };
    }

    case 'REGISTER': {
      const { user } = action.payload;

      return { ...state, isAuthenticated: true, user };
    }

    case 'CHECKUSER': {
      const { userType } = action.payload;

      return { ...state, userType: null };
    }

    default:
      return state;
  }
};

const AuthContext = createContext({
  ...initialState,
  method: 'JWT',
  login: () => {},
  logout: () => {},
  register: () => {},
  userType: () => {}
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [userParentData, setUserParentData] = useState({});
  const [sdfUserToken, setSdfUserToken] = useState(null);
  const getUserInfoUrl = Apiurl[0].api_url + 'get-user';

  const login = async (email, password) => {
    const response = await axios.post('/api/auth/login', { email, password });
    const { user } = response.data;

    dispatch({ type: 'LOGIN', payload: { user } });
  };

  const register = async (email, username, password) => {
    const response = await axios.post('/api/auth/register', { email, username, password });
    const { user } = response.data;

    dispatch({ type: 'REGISTER', payload: { user } });
  };

  const logout = () => {
    dispatch({ type: 'LOGOUT' });
  };

  const userType = (param) => {
    const sendData = {
      token: param.user_id
    };
    try {
      axios.post(getUserInfoUrl, sendData).then((res) => {
        setUserParentData(res.data.data.parent_id);
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const { data } = await axios.get('/api/auth/profile');
        dispatch({ type: 'INIT', payload: { isAuthenticated: true, user: data.user } });
      } catch (err) {
        console.error(err);
        dispatch({ type: 'INIT', payload: { isAuthenticated: false, user: null } });
      }

      const sdf_authtoken = localStorage.getItem('sdf_authToken');
      setSdfUserToken(sdf_authtoken);

      userType({ user_id: sdf_authtoken });
    })();
  }, []);

  // SHOW LOADER
  if (!state.isInitialised) return <MatxLoading />;

  return (
    <AuthContext.Provider
      value={{ ...state, method: 'JWT', login, logout, register, userParentData }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
